<template>
    <iq-card>
        <template v-slot:headerTitle>
            <div class="text-center">
                <h5 class="text-dark"><i class="ri-edit-line"></i> {{ $t('certify_form.plot_field_seed_details') }}</h5>
            </div>
        </template>
        <b-overlay>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form class="p-3"  @submit.prevent="handleSubmit(lotSubmit)" @reset.prevent="reset">
                    <b-row>
                        <b-col md="6">
                            <ValidationProvider name="Source/Scheme" vid="source_scheme">
                                <b-form-group class="row" label-cols-sm="6" label-for="source_scheme" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('certify_form.source_scheme')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        disabled
                                        plain
                                        v-model="lotOffer.source_scheme"
                                        id="source_scheme"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="Lot no" vid="lot_no">
                                <b-form-group class="row" label-cols-sm="6" label-for="lot_no" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('certify_form.lot_no')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        plain
                                        v-model="lotOffer.lot_no"
                                        id="lot_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="Seed amount" vid="seed_amount" rules="required">
                                <b-form-group class="row" label-cols-sm="6" label-for="seed_amount" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('certify_form.seed_amount')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        plain
                                        v-model="lotOffer.seed_amount"
                                        id="seed_amount"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="Total tag number" vid="total_tag_number" rules="required">
                                <b-form-group class="row" label-cols-sm="6" label-for="total_tag_number" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('certify_form.total_tag_number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        disabled
                                        plain
                                        v-model="lotOffer.total_tag"
                                        id="total_tag_number"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row v-for="(item, index) in lotOffer.items" :key="index">
                        <b-col md="6">
                            <ValidationProvider name="Unit" vid="unit_id" rules="required">
                                <b-form-group class="row" label-cols-sm="6" label-for="plot_id" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                    {{$t('research_seed.quality_unit')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="lotOffer.unit_id"
                                        :options="unitList"
                                        id="plot_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6">
                            <ValidationProvider name="Total seed amount in lot" vid="total_seed_amount_of_lot" rules="required">
                                <b-form-group class="row" label-cols-sm="6" label-for="total_seed_amount_of_lot" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                        {{$t('certify_form.packet_select')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        type="number"
                                        plain
                                        v-model="item.packet_select"
                                        id="total_seed_amount_of_lot"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="6" class="d-flex">
                            <ValidationProvider name="Total seed amount in lot" vid="demand_packet_number" rules="required" style="width: 85%;">
                                <b-form-group class="row" label-cols-sm="7" label-for="demand_packet_number" slot-scope="{ valid, errors }">
                                    <template v-slot:label>
                                        {{$t('certify_form.demand_packet_number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        class="ml-1"
                                        type="number"
                                        plain
                                        v-model="item.demand_packet"
                                        id="demand_packet_number"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div style="width: 15%;margin-left: 10px;">
                                <button v-if="lotOffer.items.length > 1" class="btn btn-sm btn-danger mr-1" @click="remove(index)" type="button"><i class="fas fa-window-close mr-0"></i></button>
                                <button type="button" class="btn btn-sm btn-success" @click="addMore(item)">
                                    <i class="fas fa-plus mr-0"></i>
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-overlay>
    </iq-card>
</template>
<script>
export default {
    data () {
        return {
            lotOffer: {
                source_scheme: '',
                lot_no: '',
                seed_amount: '',
                total_tag: '',
                items: [
                    {
                        unit_id: 0,
                        packet_select: '',
                        demand_packet: ''
                    }
                ]
            }
        }
    },
    methods: {
        addMore (item) {
            this.lotOffer.items.push(item)
        },
        remove (index) {
            this.lotOffer.items.splice(index, 1)
        }
    }
}
</script>
